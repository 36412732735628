import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

import Img from "gatsby-image"
import {
    Link,
    graphql,
    useStaticQuery,
    navigate,
    prefetchPathname,
} from "gatsby"

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}))



const Contact = () => {
    const classes = useStyles()

    const data = useStaticQuery(graphql`
  query {
    cedarmap: file(relativePath: { eq: "sa-map.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)


    return (
        <Layout>
            <SEO title="Contact Us" />
            <Container component="main" maxWidth="lg">
                <Typography component="h1" variant="h5">
                    Contact Us
                </Typography>

                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Typography gutterBottom variant="h6" style={{ marginTop: '20px' }}>
                            Johannesburg Office
                        </Typography>
                        <Typography>
                            Address: Unit 8, Cranberry Industrial Park, Cranberry Street, Honeydew, 2040
                        </Typography>
                        <Typography>
                            Office: +27 011 794 4664
                        </Typography>
                        <Typography>
                            Email: info@cedarsolar.com
                        </Typography>

                        <Typography gutterBottom variant="h6" style={{ marginTop: '20px' }}>
                            Cape Town Office
                        </Typography>
                        <Typography>
                            Address: Unit 2, Firgrove Business Park, 9 Quantum Rd, Firgrove, 7130
                        </Typography>
                        <Typography>
                            Office: +27 011 794 4664
                        </Typography>
                        <Typography>
                            Email: johan@cedarsolar.com
                        </Typography>

                        <Typography gutterBottom variant="h6" style={{ marginTop: '20px' }}>
                            Namibia Office
                        </Typography>
                        <Typography>
                            Address: 527 Dante Street, Prosperita, Windhoek, Namibia.
                        </Typography>
                        <Typography>
                            Office: +264 61 256700
                        </Typography>
                        <Typography>
                            Email: namibia@cedarsolar.com
                        </Typography>

                        <Typography gutterBottom variant="h6" style={{ marginTop: '20px' }}>
                            Customer Support
                        </Typography>
                        <Typography>
                            Support Number: +27 011 794 4664
                        </Typography>
                        <Typography>
                            Monday to Friday 08:00 – 16:30
                        </Typography>
                        <Typography>
                            Email: support@cedarsolar.com
                        </Typography>

                    </Grid>
                    <Grid item md={6}>

                        <Typography gutterBottom variant="body1" style={{ marginTop: '20px' }}>
                            We have resellers all over South Africa. Fill out the form on the home page and we will put you in touch with your nearest Cedar Solar reseller.
                        </Typography>
                        <div style={{ padding: '10px' }}>
                            <Img fluid={data.cedarmap.childImageSharp.fluid} />
                        </div>
                    </Grid>
                </Grid>





            </Container>
        </Layout>
    )
}

export default Contact
